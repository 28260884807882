// src/pages/Home.js
import React from 'react';
import Navigation from '../ui/Navigation';
import TaskList from '../components/tasks/TaskList';
import Header from '../ui/Header';


function Home() {
  return (
      <div className="flex flex-col h-screen relative">
      {/* Header (Fixed at the top with a higher z-index) */}
        <div className="z-10">
          <Header />
        </div>
        <div className="flex-grow overflow-y-auto p-4" style={{ paddingBottom: '5rem' }}>
          <TaskList />
        </div>
      <div className="z-10">
        <Navigation />
      </div>
    </div>
  );
}

export default Home;