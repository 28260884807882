// src/contexts/UsageContext.js
import { doc, onSnapshot } from "firebase/firestore";
import { default as React, useContext, useEffect, useState } from "react";
import { useAuth } from "../common/contexts/AuthContext"; // Get currentUser from AuthContext
import { firestore } from "../firebase";

const UsageContext = React.createContext();

export function useUsageContext() {
  return useContext(UsageContext);
}

const FREE_TASKS_MAX = 20;
const PAID_TASKS_MAX = -1;

export function UsageProvider({ children }) {
  const { currentUser, isSubscribed, loading } = useAuth();
  const [voiceTasksUsed, setVoiceTasksUsed] = useState(0);
  const [voiceTasksMax, setVoiceTasksMax] = useState(FREE_TASKS_MAX);

  useEffect(() => {
    if (loading || !currentUser) return;
    const userRef = doc(firestore, "users", currentUser.uid);

    const unsubscribe = onSnapshot(
      userRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          const totalVoiceTasks = userData.voiceTasks || 0; // Default to 0 if not set
          if (isSubscribed) {
            setVoiceTasksMax(() => PAID_TASKS_MAX);
          }
          setVoiceTasksUsed(totalVoiceTasks);
        }
      },
      (error) => {
        console.error("Error listening for voice tasks:", error);
      }
    );
    return unsubscribe;
  }, [loading, currentUser ? currentUser.uid : "-1"]);

  const value = {
    voiceTasksUsed,
    voiceTasksMax,
  };

  return (
    <UsageContext.Provider value={value}>
      {!loading && children}
    </UsageContext.Provider>
  );
}
