import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useAuth } from "../../common/contexts/AuthContext";
import { firestore } from "../../firebase";
import EmptyStateTaskCard from "./EmptyStateTaskCard"; // Import the EmptyStateTaskCard
import TaskCard from "./TaskCard";

function TaskList() {
  const { currentUser } = useAuth();
  const [tasks, setTasks] = useState([]);
  const [flags, setFlags] = useState([]);

  // Fetch tasks from Firebase, ordered by 'order'
  useEffect(() => {
    if (!currentUser) return;

    // Set up real-time listener for tasks, ordered only by 'order'
    const tasksQuery = query(
      collection(firestore, "users", currentUser.uid, "tasks"),
      orderBy("order") // Order by the 'order' field in Firestore
    );

    const unsubscribeTasks = onSnapshot(
      tasksQuery,
      (snapshot) => {
        const tasksData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTasks(tasksData); // No need for additional sorting
      },
      (error) => {
        console.error("Error in snapshot listener:", error);
      }
    );

    // Cleanup listener on unmount
    return () => {
      unsubscribeTasks();
    };
  }, [currentUser]);

  // **Fetch flags from Firebase and set to state**
  useEffect(() => {
    if (!currentUser) return;

    const flagsCollection = collection(
      firestore,
      "users",
      currentUser.uid,
      "flags"
    );

    const unsubscribeFlags = onSnapshot(
      flagsCollection,
      (snapshot) => {
        const flagsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFlags(flagsData);
      },
      (error) => {
        console.error("Error fetching flags:", error);
      }
    );

    // Cleanup listener on unmount
    return () => {
      unsubscribeFlags();
    };
  }, [currentUser]);

  // Handle task deletion
  const handleTaskDelete = (taskId) => {
    setTasks(tasks.filter((task) => task.id !== taskId));
  };

  // Handle task edit (including update)
const handleTaskEdit = (taskId, updatedTaskData) => {
  setTasks(
    tasks.map((task) =>
      task.id === taskId ? { ...task, ...updatedTaskData } : task
    )
  );
};

  // Handle drag end to reorder tasks
  const handleDragEnd = async (result) => {
    if (!result.destination) return; // If dropped outside the list

    const { source, destination } = result;

    // Create a copy of the tasks array
    const updatedTasks = Array.from(tasks);

    // Remove the dragged task from its original position
    const [movedTask] = updatedTasks.splice(source.index, 1);

    // Insert the dragged task at its new position
    updatedTasks.splice(destination.index, 0, movedTask);

    // Compute new order for the moved task
    let newOrder;

    if (destination.index === 0) {
      // Moved to the top
      const nextTask = updatedTasks[1];
      const nextOrder = nextTask ? nextTask.order : movedTask.order + 1;
      newOrder = nextOrder / 2;
    } else if (destination.index === updatedTasks.length - 1) {
      // Moved to the end
      const prevTask = updatedTasks[updatedTasks.length - 2];
      const prevOrder = prevTask ? prevTask.order : 0;
      newOrder = prevOrder + 1;
    } else {
      // Moved between two tasks
      const prevTask = updatedTasks[destination.index - 1];
      const nextTask = updatedTasks[destination.index + 1];
      const prevOrder = prevTask.order;
      const nextOrder = nextTask.order;
      newOrder = (prevOrder + nextOrder) / 2;
    }

    // Update the moved task's order
    movedTask.order = newOrder;

    // Update the state
    setTasks(updatedTasks);

    // Save the updated order to Firebase
    try {
      const taskRef = doc(
        firestore,
        "users",
        currentUser.uid,
        "tasks",
        movedTask.id
      );
      await updateDoc(taskRef, { order: newOrder });
    } catch (error) {
      console.error("Error updating task order:", error);
    }
  };

  return (
    <>
      {tasks.length === 0 ? (
        <div className="p-4">
          <EmptyStateTaskCard />
        </div>
      ) : (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="tasks">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="flex-grow overflow-y-auto p-4"
              >
                {tasks.map((task, index) => (
                  <Draggable key={task.id} draggableId={task.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="mb-4"
                      >
                        <TaskCard
                          task={task}
                          flags={flags}
                          onTaskDelete={handleTaskDelete}
                          onTaskEdit={handleTaskEdit}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
}
export default TaskList;
