import React from "react";
import { Route, Routes } from "react-router-dom";
import { AuthProvider } from "./common/contexts/AuthContext";
import { UsageProvider } from "./contexts/UsageContext";
import "./index.css"; // Ensure your index.css is imported
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Settings from "./pages/Settings";
import Signup from "./pages/Signup";
import PrivateRoute from "./utils/PrivateRoute";

function App() {
  return (
    <AuthProvider>
      <UsageProvider>
        <div className="center-content">
          {" "}
          {/* Apply the center-content class */}
          <Routes>
            {/* Public Routes */}
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />

            {/* Private Routes */}
            <Route path="/" element={<PrivateRoute element={Home} />} />
            <Route
              path="/settings"
              element={<PrivateRoute element={Settings} />}
            />
          </Routes>
        </div>
      </UsageProvider>
    </AuthProvider>
  );
}

export default App;
