// /src/components/voicetasks/NewVoiceTaskForm.js

import React, { useState } from "react";
import RecordingModal from "../../src/components/voicetasks/RecordingModal";
import TaskFormModal from "../../src/components/voicetasks/TaskFormModal";

function NewVoiceTaskForm({ onClose, onTaskAdded }) {
  const [showRecordingModal, setShowRecordingModal] = useState(true);
  const [taskData, setTaskData] = useState(null);

  const handleRecordingComplete = (data) => {
    setTaskData(data);
    setShowRecordingModal(false);
  };

  const handleClose = () => {
    setShowRecordingModal(false);
    setTaskData(null);
    if (onClose) {
      onClose();
    }
  };

  const handleTaskAdded = (task) => {
    if (onTaskAdded) {
      onTaskAdded(task);
    }
    handleClose();
  };

  return (
    <>
      {showRecordingModal && (
        <RecordingModal
          onCancel={handleClose}
          onRecordingComplete={handleRecordingComplete}
        />
      )}
      {taskData !== null && (
        <TaskFormModal
          onClose={handleClose}
          taskData={taskData}
          onTaskAdded={handleTaskAdded}
        />
      )}
    </>
  );
}

export default NewVoiceTaskForm;
