// src/common/contexts/AuthContext.js
import { collection, doc, getDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { auth, firestore } from "../../firebase";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      if (user) {
        const userDocRef = doc(firestore, "accounts", user.uid);
        const userDoc = await getDoc(userDocRef);

        // Check subscription status
        const customerRef = doc(collection(firestore, "customers"), user.uid);
        const customerDoc = await getDoc(customerRef);
        const customerData = customerDoc.data();
        setIsSubscribed(customerData?.activeSubscription || false);
        setLoading(false);
      } else {
        setIsSubscribed(false);
        setLoading(false);
      }
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    isSubscribed,
    loading,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
