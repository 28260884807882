import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useAuth } from "../common/contexts/AuthContext"; // Get currentUser from AuthContext
import { useUsageContext } from "../contexts/UsageContext";
import logo from "../img/logo_full.png"; // Make sure the path is correct based on your structure
function Header() {
  const { isSubscribed } = useAuth();
  const { voiceTasksUsed, voiceTasksMax } = useUsageContext();
  const [showAlert, setShowAlert] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      {!isMobile && (
        <header className="bg-white shadow-md py-4">
          <div className="container mx-auto flex justify-between items-center px-4">
            {/* Logo on the left */}
            <img src={logo} alt="Whisprlist Logo" className="h-8 w-auto" />

            {/* Display Free Voice Tasks Remaining Text */}
            {!isSubscribed && (
              <div className="flex items-center space-x-2">
                <span className="text-sm font-medium text-gray-600">
                  Free voice tasks used: {voiceTasksUsed}/{voiceTasksMax}
                </span>
              </div>
            )}
          </div>
        </header>
      )}

      {isMobile && showAlert && (
        <div className="fixed top-0 left-0 right-0 p-4 bg-blue-500 text-white text-center shadow-lg flex justify-between items-center z-50">
          <span>
            Free voice tasks used: {voiceTasksUsed}/{voiceTasksMax}
          </span>
          <button
            onClick={() => (window.location.href = "/settings")}
            className="ml-2 bg-white text-blue-500 px-3 py-1 rounded"
          >
            {isSubscribed ? "Manage" : "Upgrade"}
          </button>
          <button
            onClick={() => setShowAlert(false)}
            className="ml-2 bg-red-500 text-white px-2 py-1 rounded"
          >
            &times;
          </button>
        </div>
      )}
    </>
  );
}

export default Header;
