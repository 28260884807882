// Navigation.jsx

import React, { useState, useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai"; // Plus icon for new item
import { FaCog } from "react-icons/fa"; // Gear icon for settings
import { MdMic } from "react-icons/md"; // Microphone icon for voice task
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import NewTextTaskForm from "../components/NewTextTaskForm"; // Assuming these paths are correct
import NewVoiceTaskForm from "../components/NewVoiceTaskForm";
import { useUsageContext } from "../contexts/UsageContext";
import { collection, onSnapshot } from "firebase/firestore";
import { firestore } from "../firebase";

import { useAuth } from "../common/contexts/AuthContext";


function Navigation() {
  const [showTextForm, setShowTextForm] = useState(false);
  const [showVoiceForm, setShowVoiceForm] = useState(false);
  const [showUpgradePanel, setShowUpgradePanel] = useState(false); // New state variable
  const navigate = useNavigate(); // Initialize useNavigate
  const { voiceTasksUsed, voiceTasksMax } = useUsageContext();
  const [flags, setFlags] = useState([]);
  const { currentUser } = useAuth();

  // Fetch flags from Firebase
  useEffect(() => {
    if (!currentUser) return;

    const flagsCollection = collection(
      firestore,
      "users",
      currentUser.uid,
      "flags"
    );

    const unsubscribeFlags = onSnapshot(
      flagsCollection,
      (snapshot) => {
        const flagsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFlags(flagsData);
      },
      (error) => {
        console.error("Error fetching flags:", error);
      }
    );

    return () => {
      unsubscribeFlags();
    };
  }, [currentUser]);

const handleTextTaskClick = () => {
  navigate("/"); // Redirect to the homepage
  setShowTextForm(true); // Show the text form modal
};

const handleVoiceTaskClick = () => {
  if (voiceTasksUsed >= voiceTasksMax && voiceTasksMax !== -1) {
    setShowUpgradePanel(true);
  } else {
    navigate("/"); // Redirect to the homepage
    setShowVoiceForm(true); // Show the voice form modal
  }
};

  return (
    <>
      <div
        className="fixed z-50 w-auto h-16 max-w-lg -translate-x-1/2 bg-white border border-gray-200 rounded-full bottom-4 left-1/2 shadow-lg shadow-blue-300/20"
        style={{
          bottom: '12px',  
          boxShadow: "0 6px 15px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="grid h-full max-w-lg grid-cols-4 mx-auto">
          {/* Home button */}
          <Link
            to="/"
            className="inline-flex flex-col items-center justify-center px-5 rounded-s-full hover:bg-gray-50 group transition-all duration-300 ease-in-out"
          >
            <svg
              className="w-5 h-5 mb-1 text-gray-500 group-hover:text-blue-600 transition-all duration-300 ease-in-out"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
            </svg>
            <span className="sr-only">Home</span>
          </Link>

          {/* Plus (Create new item) button */}
          <div className="flex items-center justify-center">
            <button
              type="button"
              className="inline-flex items-center justify-center w-10 h-10 font-medium bg-blue-600 rounded-full hover:bg-blue-700 group focus:ring-4 focus:ring-blue-300 focus:outline-none transition-all duration-300 ease-in-out transform hover:scale-110 active:scale-95 active:shadow-inner"
              onClick={handleTextTaskClick} // Call the handler for text task
            >
              <AiOutlinePlus className="w-5 h-5 text-white" />
              <span className="sr-only">Create new item</span>
            </button>
          </div>

          {/* Microphone button */}
          <div className="flex items-center justify-center">
            <button
              type="button"
              className="inline-flex items-center justify-center w-10 h-10 font-medium bg-[#ff851c] rounded-full hover:bg-orange-600 group focus:ring-4 focus:ring-orange-300 focus:outline-none transition-all duration-300 ease-in-out transform hover:scale-110 active:scale-95 active:shadow-inner"
              onClick={handleVoiceTaskClick} // Call the handler for voice task
            >
              <MdMic className="w-5 h-5 text-white" />
              <span className="sr-only">Voice Task</span>
            </button>
          </div>

          {/* Settings button */}
          <Link
            to="/settings"
            className="inline-flex flex-col items-center justify-center px-5 rounded-e-full hover:bg-gray-50 group transition-all duration-300 ease-in-out"
          >
            <FaCog className="w-5 h-5 mb-1 text-gray-500 group-hover:text-blue-600 transition-all duration-300 ease-in-out" />
            <span className="sr-only">Settings</span>
          </Link>
        </div>
      </div>

      {/* Conditional rendering for forms */}
      {showTextForm && (
        <NewTextTaskForm
          onClose={() => setShowTextForm(false)}
          flags={flags} // Pass flags here
        />
      )}
      {showVoiceForm && (
        <NewVoiceTaskForm onClose={() => setShowVoiceForm(false)} />
      )}

      {/* Upgrade Panel */}
      {showUpgradePanel && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white shadow sm:rounded-lg w-full max-w-md">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                It's time to upgrade
              </h3>
              <div className="mt-2">
                <div className="max-w-xl text-sm text-gray-500">
                  <p>
                    You have now created your maximum allowed free voice tasks,
                    to be able to create more voice tasks you must subscribe.
                    For only $4.99 a month or $49.99 a year you can get
                    unlimited voice tasks.
                  </p>
                </div>
              </div>
              <div className="mt-5 sm:flex sm:flex-shrink-0 sm:items-center">
                <button
                  type="button"
                  onClick={() => {
                    setShowUpgradePanel(false);
                    navigate("/settings#billing");
                  }}
                  className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                >
                  Upgrade Now
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Navigation;