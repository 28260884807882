import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { TwitterPicker } from "react-color";
import { useAuth } from "../../common/contexts/AuthContext";
import { firestore } from "../../firebase";

function FlagsManager() {
  const [flags, setFlags] = useState([]);
  const [newFlag, setNewFlag] = useState("");
  const [color, setColor] = useState("#000000"); // Default color black
  const [isPickerOpen, setIsPickerOpen] = useState(false); // Control the color picker visibility
  const [buttonColor, setButtonColor] = useState("bg-gray-300"); // Track button color
  const { currentUser } = useAuth();
  const pickerRef = useRef(null); // Reference to color picker for detecting outside clicks

  // Fetch flags from Firebase
  useEffect(() => {
    const fetchFlags = async () => {
      if (currentUser) {
        const flagsCollection = collection(
          firestore,
          "users",
          currentUser.uid,
          "flags"
        );
        const flagsSnapshot = await getDocs(flagsCollection);
        const flagsData = flagsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFlags(flagsData);
      }
    };
    fetchFlags();
  }, [currentUser]);

  // Handle outside click to close the color picker
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setIsPickerOpen(false);
      }
    };

    if (isPickerOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isPickerOpen]);

  const handleAddFlag = async () => {
    if (newFlag.trim()) {
      const newFlagData = { name: newFlag.trim(), color };

      // Save the new flag to Firebase
      if (currentUser) {
        const flagsCollection = collection(
          firestore,
          "users",
          currentUser.uid,
          "flags"
        );
        const docRef = await addDoc(flagsCollection, newFlagData);

        // Add the new flag locally
        setFlags([...flags, { id: docRef.id, ...newFlagData }]);
        setNewFlag(""); // Clear the input field
        setButtonColor("bg-gray-300"); // Reset button color to grey after adding the flag
      }
    }
  };

  const handleDeleteFlag = async (id) => {
    if (currentUser) {
      await deleteDoc(doc(firestore, "users", currentUser.uid, "flags", id));
      setFlags(flags.filter((flag) => flag.id !== id));
    }
  };

  const handleUpdateFlag = async (id, updatedName, updatedColor) => {
    if (currentUser) {
      const flagRef = doc(firestore, "users", currentUser.uid, "flags", id);
      await updateDoc(flagRef, { name: updatedName, color: updatedColor });
      setFlags(
        flags.map((flag) =>
          flag.id === id ? { id, name: updatedName, color: updatedColor } : flag
        )
      );
    }
  };

  const handleColorChange = (color) => {
    setColor(color.hex); // Update the selected color
    setButtonColor(""); // Remove previous background class to apply inline style
    setIsPickerOpen(false); // Close picker after selecting color
  };

  return (
    <div className="max-w-full mx-4 my-6 p-6 bg-white shadow-md rounded relative">
      <h2 className="text-2xl font-bold mb-2">Create Flags</h2>
      <div className="border-b border-gray-300 mb-4"></div>

      <p className="mb-4">
        Creating flags will allow you to better manage what tasks are important
        or urgent. Voice tasks will automatically assign flags based on task
        content.
      </p>

      {/* Flag Creation - Full Width Input and Buttons Below */}
      <div className="mb-4">
        {/* Flag name input (Full width) */}
        <input
          type="text"
          value={newFlag}
          onChange={(e) => setNewFlag(e.target.value)}
          placeholder="New flag"
          className="w-full px-3 py-2 border rounded focus:outline-none focus:ring"
        />
      </div>

      {/* Row with Color button and Add Flag button */}
      <div className="flex items-center justify-between space-x-4 mb-8">
        {/* Color picker button */}
        <div className="relative">
          <button
            onClick={() => setIsPickerOpen(!isPickerOpen)}
            className={`${buttonColor} text-black px-4 py-2 rounded shadow hover:bg-gray-400`}
            style={{ backgroundColor: buttonColor === "" ? color : undefined }} // Inline color if picker is selected
          >
            Color
          </button>
          {isPickerOpen && (
            <div ref={pickerRef} className="absolute mt-2 z-10 w-48 h-48">
              <TwitterPicker
                color={color}
                onChangeComplete={handleColorChange}
              />
            </div>
          )}
        </div>

        {/* Add flag button */}
        <button
          onClick={handleAddFlag}
          className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
        >
          Add Flag
        </button>
      </div>
      {/* Existing Flags */}
      <ul>
        {flags.map((flag) => (
          <li key={flag.id} className="mb-2">
            <div className="flex justify-between items-center">
              <div>
                <span
                  className="inline-block px-3 py-1 rounded-sm text-xs"
                  style={{
                    backgroundColor: flag.color,
                    color: "#fff", // Force white text for contrast
                  }}
                >
                  {flag.name}
                </span>
              </div>
              <div>
                <button
                  onClick={() => handleDeleteFlag(flag.id)}
                  className="text-red-500 hover:text-red-700 ml-4"
                >
                  Delete
                </button>
                <button
                  onClick={() =>
                    handleUpdateFlag(
                      flag.id,
                      prompt("New name:", flag.name),
                      flag.color
                    )
                  }
                  className="text-blue-500 hover:text-blue-700 ml-2"
                >
                  Edit
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FlagsManager;
