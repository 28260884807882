// src/components/NewTextTaskForm.js
import { Box, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useAuth } from "../common/contexts/AuthContext";
import { firestore } from "../firebase";


function NewTextTaskForm({ onClose, onTaskAdded, flags }) {
  const { currentUser } = useAuth();
  const [title, setTitle] = useState("");
  const [notes, setNotes] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const [dueTime, setDueTime] = useState(null);
  const [selectedFlag, setSelectedFlag] = useState("");
  const [isClosing, setIsClosing] = useState(false);
  const formRef = useRef();
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      ["link"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      ["clean"],
    ],
  };

   const handleClose = useCallback(() => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
    }, 30);
  }, [onClose]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        formRef.current &&
        !formRef.current.contains(event.target) &&
        !isPickerOpen // Do not close if picker is open
      ) {
        handleClose();
      }
    };
    document.addEventListener("pointerdown", handleClickOutside);
    return () => {
      document.removeEventListener("pointerdown", handleClickOutside);
    };
  }, [handleClose, isPickerOpen]);

  // Function to update task count in the user's document
  const updateTaskCount = async (taskType) => {
    try {
      const userRef = doc(firestore, "users", currentUser.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        // Increment the task count based on task type
        const userData = userDoc.data();
        const newTaskCount = (userData[`${taskType}Tasks`] || 0) + 1;

        // Update the document with the new task count
        await updateDoc(userRef, {
          [`${taskType}Tasks`]: newTaskCount,
        });
      } else {
        // If the user document doesn't exist (rare case), initialize it with the task count
        await updateDoc(userRef, {
          [`${taskType}Tasks`]: 1,
        });
      }
    } catch (error) {
      console.error("Error updating task count:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title.trim()) {
      alert("Title is required.");
      return;
    }
    handleClose();
    try {
      // Fetch current tasks to determine the last order number
      const tasksQuery = query(
        collection(firestore, `users/${currentUser.uid}/tasks`),
        orderBy("order", "desc")
      );
      const tasksSnapshot = await getDocs(tasksQuery);
      let lastOrder = 0;
      if (!tasksSnapshot.empty) {
        lastOrder = tasksSnapshot.docs[0].data().order;
      }

      // Create the new text task
      const newTask = {
        title,
        notes,
        dueDate: dueDate ? dueDate.format("YYYY-MM-DD") : null,
        dueTime: dueTime ? dueTime.format("HH:mm:ss") : null,
        flag: selectedFlag || null,
        status: "in progress",
        creationDate: new Date(),
        taskType: "text",
        order: lastOrder + 1,
      };

      const taskDoc = await addDoc(
        collection(firestore, `users/${currentUser.uid}/tasks`),
        newTask
      );
      newTask.id = taskDoc.id;

      // Update task count for text tasks in the user's document
      await updateTaskCount("text"); // This will increment the total number of text tasks

      if (onTaskAdded) {
        onTaskAdded(newTask); // Call the parent callback to update the UI
      }

      handleClose();
    } catch (error) {
      console.error("Error adding task: ", error);
      alert("An error occurred while adding the task. Please try again.");
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-40 flex justify-center items-center p-4 md:p-0">
      <div
        className={`relative bg-white p-6 rounded-lg w-full max-w-lg transition-all duration-300 transform ${
          isClosing ? "animate-slide-down" : "animate-slide-up"
        }`}
        ref={formRef}
        style={{ borderRadius: "16px", padding: "20px" }}
      >
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          &#x2715;
        </button>

        <h2 className="text-xl font-bold mb-4 text-center">Add New Task</h2>

        <form onSubmit={handleSubmit}>
          {/* Task Title Input */}
          <div className="mb-4">
            <label className="block mb-1 text-sm font-semibold">
              Task Title
            </label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-150"
            />
          </div>

          {/* Notes Input (Quill Editor) */}
          <div className="mb-4">
            <label className="block mb-1 text-sm font-semibold">Notes</label>
            <div className="quill-container h-40 rounded-lg overflow-hidden border border-gray-300 focus-within:ring-2 focus-within:ring-blue-500">
            <ReactQuill
                value={notes}
                onChange={setNotes}
                theme="snow"
                className="my-quill-editor h-40"
                modules={modules}
              />
            </div>
          </div>

          {/* Due Date and Time Picker */}
          <div className="mb-4 flex flex-col md:flex-row gap-4">
            <Box sx={{ flex: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Select due date"
                  value={dueDate}
                  onChange={(newDate) => setDueDate(newDate)}
                  onOpen={() => setIsPickerOpen(true)}
                  onClose={() => setIsPickerOpen(false)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Box>

            <Box sx={{ flex: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Select due time"
                  value={dueTime}
                  onChange={(newTime) => setDueTime(newTime)}
                  onOpen={() => setIsPickerOpen(true)}
                  onClose={() => setIsPickerOpen(false)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Box>
          </div>

            {/* Flag Selector */}
            <div className="mb-4 flex flex-col md:flex-row gap-4">
            <Box sx={{ flex: 1 }} className="relative">
              <label
                htmlFor="flag"
                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-400"
              >
                Select Flag
              </label>
              <select
                id="flag"
                value={selectedFlag}
                onChange={(e) => setSelectedFlag(e.target.value)}
                className="block w-full rounded-md border border-gray-300 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6 p-4 transition-all duration-150"
                style={{ minHeight: "56px" }}
              >
                <option value="">Select A Flag</option>
                {flags.map((flag) => (
                  <option key={flag.id} value={flag.id}>
                    {flag.name}
                  </option>
                ))}
              </select>
            </Box>
          </div>





          {/* Actions */}
          <div className="flex justify-end mt-6">
            <button
              type="button"
              onClick={handleClose}
              className="mr-4 text-gray-500 hover:text-gray-700 transition-all duration-150"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-all duration-150"
            >
              Add Task
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NewTextTaskForm;
