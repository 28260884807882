import { addDoc, collection, onSnapshot } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import React, { useState } from "react"; // Import useState
import { useAuth } from "../../common/contexts/AuthContext";
import {
  STRIPE_ANNUAL_PRICE_ID,
  STRIPE_MONTHLY_PRICE_ID,
  STRIPE_PORTAL_ID,
} from "../../constants.js";
import { firestore, functions } from "../../firebase";

export default function AccountCard() {
  const { currentUser, isSubscribed } = useAuth(); // Get the logged-in user and subscription status
  const [loading, setLoading] = useState(false); // Add loading state

  // Function to handle Billing Portal button click
  const handleBillingPortal = async () => {
    setLoading(true); // Set loading to true
    try {
      const functionRef = httpsCallable(
        functions,
        "ext-firestore-stripe-payments-createPortalLink"
      );

      const { data } = await functionRef({
        returnUrl: window.location.origin,
        locale: "auto", // Optional, defaults to "auto"
        configuration: STRIPE_PORTAL_ID,
      });
      window.location.assign(data.url);
    } catch (error) {
      alert(`An error occurred: ${error.message}`);
      setLoading(false); // Reset loading state on error
    }
  };

  // Function to handle Subscribe Monthly button click
  const handleSubscribeMonthly = async () => {
    setLoading(true); // Set loading to true
    await checkoutWithPrice(STRIPE_MONTHLY_PRICE_ID);
  };

  // Function to handle Subscribe Annual button click
  const handleSubscribeAnnual = async () => {
    setLoading(true); // Set loading to true
    await checkoutWithPrice(STRIPE_ANNUAL_PRICE_ID);
  };

  // Function to create a checkout session and redirect
  const checkoutWithPrice = async (priceId) => {
    try {
      console.log("user id", currentUser.uid);

      const checkoutSessionsRef = collection(
        firestore,
        "customers",
        currentUser.uid,
        "checkout_sessions"
      );

      // Adding a new document to the checkout_sessions collection
      const docRef = await addDoc(checkoutSessionsRef, {
        price: priceId,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });

      // Listening for changes on the added document (real-time updates)
      onSnapshot(docRef, (snap) => {
        const data = snap.data();
        if (data) {
          const { error, url } = data;
          if (error) {
            alert(`An error occurred: ${error.message}`);
            setLoading(false); // Reset loading state on error
          }
          if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(url);
          }
        }
      });
    } catch (error) {
      alert(`An error occurred: ${error.message}`);
      setLoading(false); // Reset loading state on error
    }
  };

  // Inside your component's return statement

return (
  <div className="max-w-full mx-4 my-6 p-6 bg-white shadow-md rounded">
    <div>
      <h2 className="text-2xl font-semibold mb-4">Billing</h2>
      <div className="mb-6 text-center">
        {isSubscribed ? (
          <p className="text-green-600 text-lg font-semibold">
            Thank you for your active subscription!
          </p>
        ) : (
          <h3 className="text-xl font-semibold text-blue-600">
            Ready to unlock unlimited voice tasks?
          </h3>
        )}
      </div>

      {/* Loader when loading */}
      {loading ? (
        <div className="flex flex-col items-center">
          <h2 className="text-4xl font-bold text-gray-800 mb-4">
            Loading...
          </h2>
          <p className="text-base text-gray-600 mb-4">
            Please be patient, this can take a few seconds.
          </p>
          <div className="flex gap-2">
            <div className="w-6 h-6 rounded-full bg-green-500 animate-bounce"></div>
            <div className="w-6 h-6 rounded-full bg-green-500 animate-bounce [animation-delay:-0.2s]"></div>
            <div className="w-6 h-6 rounded-full bg-green-500 animate-bounce [animation-delay:-0.4s]"></div>
          </div>
        </div>
      ) : (
        <>
          {/* Subscription options */}
          {!isSubscribed && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Monthly Subscription */}
              <div className="border p-6 rounded-lg shadow-sm">
                <h3 className="text-xl font-semibold mb-2 text-center">
                  Monthly Subscription
                </h3>
                <div className="flex items-baseline justify-center text-gray-900 mb-4">
                  <span className="text-3xl font-semibold">$</span>
                  <span className="text-5xl font-extrabold tracking-tight">
                    4.99
                  </span>
                  <span className="text-xl font-normal text-gray-500 ml-1">
                    /month
                  </span>
                </div>
                <div className="flex justify-end">
                  <button
                    className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
                    onClick={handleSubscribeMonthly}
                  >
                    Subscribe Monthly
                  </button>
                </div>
              </div>

              {/* Annual Subscription */}
              <div className="border p-6 rounded-lg shadow-sm">
                <h3 className="text-xl font-semibold mb-2 text-center">
                  Annual Subscription
                </h3>
                <div className="flex items-baseline justify-center text-gray-900 mb-4">
                  <span className="text-3xl font-semibold">$</span>
                  <span className="text-5xl font-extrabold tracking-tight">
                    49.99
                  </span>
                  <span className="text-xl font-normal text-gray-500 ml-1">
                    /year
                  </span>
                </div>
                <div className="flex justify-end">
                  <button
                    className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
                    onClick={handleSubscribeAnnual}
                  >
                    Subscribe Annually
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Billing Portal */}
          <div className="mt-8 p-4 border-t border-gray-200">
            <h3 className="text-md font-medium text-gray-900">
              Go to your Billing Portal
            </h3>
            <p className="text-sm text-gray-600 mb-4">
              {isSubscribed
                ? "Manage your subscription, update payment details, or view invoices."
                : "Manage your subscription, update payment details, or view invoices in the billing portal."}
            </p>
            <div className="flex justify-end">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
                onClick={handleBillingPortal}
              >
                Billing Portal
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  </div>
);
}