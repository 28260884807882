// src/utils/PrivateRoute.js
import CircularProgress from "@mui/material/CircularProgress"; // Example using Material-UI
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../common/contexts/AuthContext";

function PrivateRoute({ element: Element }) {
  const { currentUser, loading } = useAuth();

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  }

  return currentUser ? <Element /> : <Navigate to="/login" />;
}

export default PrivateRoute;
