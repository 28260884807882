// src/components/BillingCard.js

import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import React, { useState } from "react";
import { useAuth } from "../../common/contexts/AuthContext"; // Ensure you're using the context that provides Firebase Auth

function AccountCard() {
  const { currentUser } = useAuth(); // Get currentUser from AuthContext
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState(""); // For displaying success/error messages
  const [error, setError] = useState(""); // To display specific error messages

  // Function to update the password
  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");

    // If the new password is the same as the current password, show an error
    if (currentPassword === newPassword) {
      setError("New password cannot be the same as the current password.");
      return;
    }

    // Get the user's credentials
    const credential = EmailAuthProvider.credential(
      currentUser.email,
      currentPassword
    );

    try {
      // Reauthenticate the user with current password
      await reauthenticateWithCredential(currentUser, credential);

      // Update the password with the new one
      await updatePassword(currentUser, newPassword);
      setMessage("Password updated successfully!");
      setCurrentPassword(""); // Clear the current password input
      setNewPassword(""); // Clear the new password input
    } catch (error) {
      if (error.code === "auth/wrong-password") {
        setError("The current password is incorrect.");
      } else if (error.code === "auth/weak-password") {
        setError(
          "The new password is too weak. Please choose a stronger password."
        );
      } else if (error.code === "auth/invalid-credential") {
        setError("Invalid credentials. Please try again.");
      } else {
        setError(`Error updating password: ${error.message}`);
      }
    }
  };

  // Render early if `currentUser` is null
  if (!currentUser) {
    return (
      <div className="max-w-full mx-4 my-6 p-6 bg-white shadow-md rounded">
        <p className="text-red-500">
          You are not logged in. Please log in to view your account details.
        </p>
      </div>
    );
  }

  return (
    <div className="max-w-full mx-4 my-6 p-6 bg-white shadow-md rounded">
      <div>
        <h3 className="text-2xl font-bold mb-2">Account</h3>
        <div className="border-b border-gray-300 mb-4"></div>

        {/* Display User Email */}
        <p className="mb-4 text-gray-600">
          <strong>Email:</strong> {currentUser.email}
        </p>
      </div>

      {/* Password Update Form */}
      <div className="mb-6">
        <h4 className="text-lg font-bold mb-2">Update Password</h4>
        <form onSubmit={handleUpdatePassword}>
          <div className="flex flex-col md:flex-row md:space-x-4">
            {/* Current Password Field */}
            <div className="flex-1 mb-4">
              <label className="block mb-1" htmlFor="currentPassword">
                Current Password
              </label>
              <input
                type="password"
                id="currentPassword"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
                className="w-full px-3 py-2 border rounded"
              />
            </div>

            {/* New Password Field */}
            <div className="flex-1 mb-4">
              <label className="block mb-1" htmlFor="newPassword">
                New Password
              </label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                className="w-full px-3 py-2 border rounded"
              />
            </div>
          </div>

          {/* Message (success/error) */}
          {error && (
            <div className="text-center mb-4">
              <p className="text-sm text-red-500">{error}</p>
            </div>
          )}

          {message && (
            <div className="text-center mb-4">
              <p className="text-sm text-green-500">{message}</p>
            </div>
          )}

          {/* Update Button */}
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-green-500 text-white py-2 px-4 rounded shadow hover:bg-green-600"
            >
              Update Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AccountCard;
