import { isBefore, isSameDay, parse, startOfDay } from "date-fns";
import DOMPurify from "dompurify";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState, useRef } from "react";
import { FaBell } from "react-icons/fa";
import { useAuth } from "../../common/contexts/AuthContext";
import { firestore } from "../../firebase";
import TaskEdit from "../TaskEdit";

function TaskCard({
  task,
  flags,
  onTaskDelete,
  onTaskEdit,
  currentExpandedTaskId,
  onTaskExpand,
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [taskFlag, setTaskFlag] = useState(null);
  const { currentUser } = useAuth();
  const menuRef = useRef(null);
  const cardRef = useRef(null);
  const notesRef = useRef(null);

  // Resolve the task's associated flag
  useEffect(() => {
    if (flags.length > 0 && task.flag) {
      const flag = flags.find((flag) => flag.id === task.flag);
      setTaskFlag(flag || null);
    }
  }, [flags, task.flag]);

  // Handle Delete
  const handleDelete = async () => {
    try {
      const taskRef = doc(
        firestore,
        "users",
        currentUser.uid,
        "tasks",
        task.id
      );
      await deleteDoc(taskRef);
      onTaskDelete(task.id);
      setMenuOpen(false);
    } catch (error) {
      console.error("Error deleting task:", error);
    }
  };

  // Handle Edit Save
  const handleTaskEdit = async (updatedTask) => {
    try {
      const taskRef = doc(
        firestore,
        "users",
        currentUser.uid,
        "tasks",
        task.id
      );
      await updateDoc(taskRef, updatedTask);
      onTaskEdit(task.id, updatedTask);
      setIsEditing(false);
      setMenuOpen(false);
    } catch (error) {
      console.error("Error updating task:", error);
    }
  };

  // Check if notes exceed three lines and display "More" button
  useEffect(() => {
    const checkNotesHeight = () => {
      if (notesRef.current) {
        const threeLineHeight = parseFloat(
          getComputedStyle(notesRef.current).lineHeight
        ) * 3;
        if (notesRef.current.scrollHeight > threeLineHeight) {
          setShowMoreButton(true);
        } else {
          setShowMoreButton(false);
        }
      }
    };

    checkNotesHeight();
    window.addEventListener("resize", checkNotesHeight);
    return () => window.removeEventListener("resize", checkNotesHeight);
  }, [task.notes]);

  // Close the menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current && !menuRef.current.contains(event.target) &&
        cardRef.current && !cardRef.current.contains(event.target)
      ) {
        setMenuOpen(false);
        onTaskExpand(null); // Close the expanded task when clicking outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Parse and compare due dates
  const dueDateParsed = task.dueDate
    ? parse(task.dueDate, "yyyy-mm-dd", new Date())
    : null;

  const today = startOfDay(new Date());

  let dueDateColor = "text-gray-500";

  if (dueDateParsed) {
    if (isSameDay(dueDateParsed, today)) {
      dueDateColor = "text-green-500";
    } else if (isBefore(dueDateParsed, today)) {
      dueDateColor = "text-red-500";
    }
  }

  // Sanitize the notes content
  const sanitizedNotes = DOMPurify.sanitize(task.notes);

  return (
    <div className="bg-white p-4 shadow-md rounded-lg flex flex-col justify-between h-full relative" ref={cardRef}>
      <div className="flex justify-between items-start">
        <div>
          <h3 className="text-md font-semibold mb-1">{task.title}</h3>
          {task.notes && (
            <div className="text-gray-600 mb-2 text-sm">
              <div
                className={`overflow-hidden ${!isExpanded && 'max-h-[4.5em]'}`} // 4.5em represents 3 lines of text
                ref={notesRef}
                style={{ lineHeight: '1.5em' }}
                dangerouslySetInnerHTML={{
                  __html: sanitizedNotes,
                }}
              />
              {showMoreButton && !isExpanded && (
                <button
                  onClick={() => setIsExpanded(true)}
                  className="text-blue-500 text-xs mt-3"
                >
                  ...More
                </button>
              )}
              {isExpanded && (
                <button
                  onClick={() => setIsExpanded(false)}
                  className="text-blue-500 text-xs mt-1"
                >
                  Close
                </button>
              )}
            </div>
          )}
        </div>

        {/* Hamburger Dots and Reminder Icon */}
        <div className="relative flex items-center">
          {task.reminderEnabled && <FaBell className="text-green-500 mr-2" />}
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-gray-400 hover:text-gray-600 text-lg"
          >
            •••
          </button>
          {menuOpen && (
            <div
              className="absolute right-0 mt-1 w-28 bg-white border rounded shadow-md z-10"
              ref={menuRef}
            >
              <button
                onClick={() => {
                  setIsEditing(true);
                  setMenuOpen(false);
                }}
                className="block w-full px-3 py-2 text-left hover:bg-gray-100 text-sm"
              >
                Edit
              </button>
              <button
                onClick={handleDelete}
                className="block w-full px-3 py-2 text-left hover:bg-gray-100 text-sm"
              >
                Delete
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-between items-center mt-2">
        <p className={`text-xs ${dueDateColor}`}>
          Due: {task.dueDate || "No Due Date"}{" "}
          {task.dueTime && `At: ${task.dueTime}`}
        </p>
        {taskFlag && (
          <div className="mt-1">
            <span
              className="inline-block px-2 py-1 rounded-sm text-xs"
              style={{
                backgroundColor: taskFlag.color,
                color: "#fff",
              }}
            >
              {taskFlag.name}
            </span>
          </div>
        )}
      </div>

      {isEditing && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-lg w-full max-w-md">
            <TaskEdit
              task={task}
              flags={flags}
              onClose={() => setIsEditing(false)}
              onTaskUpdated={handleTaskEdit}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default TaskCard;