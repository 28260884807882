import React, { useState } from 'react';
import { Box, TextField } from '@mui/material';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

function TaskEdit({ task, onClose, onTaskUpdated, flags }) {
  const [title, setTitle] = useState(task.title);
  const [notes, setNotes] = useState(task.notes);
  const [dueDate, setDueDate] = useState(task.dueDate ? dayjs(task.dueDate, 'YYYY-MM-DD') : null);
  const [dueTime, setDueTime] = useState(task.dueTime ? dayjs(task.dueTime, 'HH:mm:ss') : null);  
  const [selectedFlag, setSelectedFlag] = useState(task.flag || '');
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['link'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['clean'],
    ],
  };
  const handleSave = () => {
    try {
      const updatedTask = {
        title,
        notes,
        dueDate: dueDate ? dueDate.format('YYYY-MM-DD') : null,
        dueTime: dueTime ? dueTime.format('HH:mm:ss') : null,
        flag: selectedFlag,
      };

      onTaskUpdated(updatedTask);
      onClose();
    } catch (error) {
      console.error('Error formatting date/time:', error);
      alert('An error occurred while saving the task. Please try again.');
    }
  };

  

  return (
    <div className="p-4 md:p-6 bg-white rounded-lg max-w-lg w-full mx-auto">
      <h3 className="text-xl font-semibold mb-4 text-center">Edit Task</h3>
      <form onSubmit={(e) => e.preventDefault()}>
        {/* Task Title */}
        <div className="mb-4">
          <label className="block mb-1 text-sm font-semibold">Task Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-150"
          />
        </div>

        {/* Task Notes */}
        <div className="mb-4">
          <label className="block mb-1 text-sm font-semibold">Notes</label>
          <div className="quill-container h-40 rounded-lg overflow-hidden border border-gray-300 focus-within:ring-2 focus-within:ring-blue-500">
            <ReactQuill
              value={notes}
              onChange={setNotes}
              theme="snow"
              className="h-full"
              modules={modules}
            />
          </div>
        </div>

        {/* Due Date Picker and Time Picker */}
        <div className="mb-4 flex flex-col md:flex-row gap-4">
          <Box sx={{ flex: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Select due date"
                value={dueDate}
                onChange={(newDate) => setDueDate(newDate)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Box>

          <Box sx={{ flex: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Select due time"
                value={dueTime}
                onChange={(newTime) => setDueTime(newTime)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Box>
        </div>

        
        {/* Flag Selector */}
        <div className="mb-4 flex flex-col md:flex-row gap-4">
          <Box sx={{ flex: 1 }} className="relative">
            <label
              htmlFor="flag"
              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-400"
            >
              Select Flag
            </label>
            <select
              id="flag"
              value={selectedFlag}
              onChange={(e) => setSelectedFlag(e.target.value)}
              className="block w-full rounded-md border border-gray-300 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6 p-4 transition-all duration-150"
              style={{ minHeight: "56px" }}
            >
              <option value="">No Flag</option>
              {flags.map((flag) => (
                <option key={flag.id} value={flag.id}>
                  {flag.name}
                </option>
              ))}
            </select>
          </Box>
        </div>

        {/* Buttons */}
        <div className="flex justify-end space-x-4 mt-6">
          <button
            type="button"
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 transition-all duration-150"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleSave}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-all duration-150"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
}

export default TaskEdit;