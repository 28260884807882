// /src/components/voicetasks/TaskFormModal.jsx

import React, { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useAuth } from "../../common/contexts/AuthContext";
import { firestore } from "../../firebase";

dayjs.extend(customParseFormat);

function TaskFormModal({ onClose, taskData, onTaskAdded }) {
  const { currentUser } = useAuth();
  const [taskInfo, setTaskInfo] = useState(taskData.task || {});
  const [notes, setNotes] = useState(taskData.task?.notes || "");
  const [flags, setFlags] = useState([]);
  const [selectedFlag, setSelectedFlag] = useState("");
  const [isClosing, setIsClosing] = useState(false);

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      ["link"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      ["clean"],
    ],
  };

  // Fetch flags
  useEffect(() => {
    const fetchFlags = async () => {
      try {
        const flagsCollection = collection(
          firestore,
          "users",
          currentUser.uid,
          "flags"
        );
        const flagsSnapshot = await getDocs(flagsCollection);
        const flagsData = flagsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFlags(flagsData);
      } catch (error) {
        console.error("Error fetching flags:", error);
        setFlags([]);
      }
    };
    fetchFlags();
  }, [currentUser.uid]);

  // Handle adding the task
  const handleAddTask = async (e) => {
    e.preventDefault();

    if (!taskInfo.title.trim()) {
      alert("Title is required.");
      return;
    }
    handleClose();
    try {
      // Fetch current tasks to determine the last order number
      const tasksQuery = query(
        collection(firestore, `users/${currentUser.uid}/tasks`),
        orderBy("order", "desc")
      );
      const tasksSnapshot = await getDocs(tasksQuery);
      let lastOrder = 0;
      if (!tasksSnapshot.empty) {
        lastOrder = tasksSnapshot.docs[0].data().order;
      }

      // Create the new task with an incremented order value
      const newTask = {
        title: taskInfo.title,
        notes: notes || "",
        dueDate: taskInfo.dueDate || null,
        dueTime: taskInfo.dueTime || null,
        flag: selectedFlag || null,
        status: "in progress",
        creationDate: new Date(),
        taskType: "voice",
        order: lastOrder + 1,
      };

      // Add the new task to Firestore
      const taskDoc = await addDoc(
        collection(firestore, `users/${currentUser.uid}/tasks`),
        newTask
      );
      newTask.id = taskDoc.id;

      // Update the task count in Firestore
      await updateTaskCount("voice");

      if (onTaskAdded) {
        onTaskAdded(newTask);
      }
    } catch (error) {
      console.error("Error adding task: ", error);
      alert("An error occurred while adding the task. Please try again.");
    }
  };

  // Function to update task count in the user's document
  const updateTaskCount = async (taskType) => {
    try {
      const userRef = doc(firestore, "users", currentUser.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const newTaskCount = (userData[`${taskType}Tasks`] || 0) + 1;

        await updateDoc(userRef, {
          [`${taskType}Tasks`]: newTaskCount,
        });
      } else {
        await setDoc(
          userRef,
          {
            [`${taskType}Tasks`]: 1,
          },
          { merge: true }
        );
      }
    } catch (error) {
      console.error("Error updating task count:", error);
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
    }, 10);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div
        className={`relative bg-white p-6 rounded-lg w-full max-w-md transition-all duration-300 ${
          isClosing ? "animate-slide-down" : "animate-slide-up"
        }`}
      >
        {/* Close Button */}
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          &#x2715;
        </button>

        {/* Header */}
        <h2 className="text-xl font-bold mb-4 text-center">Create Task</h2>

        {/* Task Creation Form */}
        <div className="mb-4">
          <h3 className="text-lg font-bold mb-2">Generated Task:</h3>
          <form onSubmit={handleAddTask}>
            {/* Task Title */}
            <div className="mb-4">
              <label className="block mb-1">Task Title</label>
              <TextField
                value={taskInfo.title}
                onChange={(e) =>
                  setTaskInfo({ ...taskInfo, title: e.target.value })
                }
                required
                fullWidth
                variant="outlined"
              />
            </div>

            {/* Notes Input (Quill Editor) */}
            <div className="mb-4">
              <label className="block mb-1 text-sm font-semibold">Notes</label>
              <div className="quill-container h-40 rounded-lg overflow-hidden border border-gray-300 focus-within:ring-2 focus-within:ring-blue-500">
                <ReactQuill
                  value={notes}
                  onChange={setNotes}
                  theme="snow"
                  className="my-quill-editor h-40 "
                  modules={modules}
                />
              </div>
            </div>

            {/* Due Date and Due Time */}
            <div className="mb-4 flex gap-4">
              {/* Due Date */}
              <Box sx={{ flex: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Select due date"
                    value={
                      taskInfo.dueDate
                        ? dayjs(taskInfo.dueDate, "YYYY-MM-DD")
                        : null
                    }
                    onChange={(date) =>
                      setTaskInfo({
                        ...taskInfo,
                        dueDate: date ? date.format("YYYY-MM-DD") : null,
                      })
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Box>

              {/* Due Time */}
              <Box sx={{ flex: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Select due time"
                    value={
                      taskInfo.dueTime
                        ? dayjs(taskInfo.dueTime, "HH:mm:ss")
                        : null
                    }
                    onChange={(time) =>
                      setTaskInfo({
                        ...taskInfo,
                        dueTime: time ? time.format("HH:mm:ss") : null,
                      })
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </div>

            {/* Flag Selection */}
            <div className="mb-6">
              <label className="block mb-1">Select a Flag</label>
              <select
                value={selectedFlag}
                onChange={(e) => setSelectedFlag(e.target.value)}
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring p-4"
              >
                <option value="">Select A Flag</option>
                {flags.map((flag) => (
                  <option key={flag.id} value={flag.id}>
                    {flag.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Form Buttons */}
            <div className="flex justify-end mt-6">
              <button
                type="button"
                onClick={handleClose}
                className="mr-4 text-gray-500 hover:text-gray-700"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Add Task
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default TaskFormModal;