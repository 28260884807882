// Settings.jsx

import React from "react";
import Navigation from "../ui/Navigation";
import FlagsManager from "../components/settings/FlagsManager";
import AccountCard from "../components/settings/AccountCard";
import ContactCard from "../components/settings/ContactCard";
import WebhookCard from "../components/settings/WebhookCard";
import Header from "../ui/Header";
import PwaInstall from "../components/settings/PwaInstall"; // Import PwaInstall component
import BillingModal from "../containers/Billing/BillingModal";
import LogoutButton from "../ui/LogoutButton"; // Import the Logout button

function Settings() {
  return (
    <div className="flex flex-col h-screen relative">
      {/* Header (Fixed at the top with a higher z-index) */}
      <div className="z-10">
        <Header />
      </div>

      {/* Main content, scrollable under the header */}
      <div
        className="flex-grow overflow-y-auto p-4"
        style={{ paddingBottom: "5rem" }}
      >
        {/* Other Cards */}
        <FlagsManager />
        <WebhookCard />
        <AccountCard />
        <BillingModal />
        <ContactCard />

        <div className="max-w-full mx-4 my-6 p-6 bg-white shadow-md rounded">
          <div className="flex justify-end">
            <LogoutButton />
          </div>
        </div>
      </div>

      {/* Navigation (Fixed at the bottom) */}
      <div className="z-10">
        <Navigation />
      </div>
    </div>
  );
}

export default Settings;
