// /src/components/voicetasks/RecordingModal.jsx

import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../common/contexts/AuthContext";
import { initializeRecorder } from "../../utils/audioRecorder";

function RecordingModal({ onCancel, onRecordingComplete }) {
  const { currentUser } = useAuth();
  const [recordingState, setRecordingState] = useState("idle");
  const [countdown, setCountdown] = useState(15); // Countdown set to 15 seconds
  const countdownRef = useRef(null);
  const [startButtonDisabled, setStartButtonDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [audioBlobUrl, setAudioBlobUrl] = useState(null);
  const [audioRecorder, setAudioRecorder] = useState(null);
  const [stream, setStream] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const recordingTimeoutRef = useRef(null);
  const [microphoneStream, setMicrophoneStream] = useState(null);

  // Function to display error with custom styling
  const showError = (message) => {
    return (
      <div className="border-l-4 border-red-400 bg-red-50 p-4 mb-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon
              aria-hidden="true"
              className="h-5 w-5 text-red-400"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm text-red-700">{message}</p>
          </div>
        </div>
      </div>
    );
  };

  const initializeMicrophone = async () => {
    try {
      const userStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      setMicrophoneStream(userStream);
      const recorder = await initializeRecorder(userStream);
      setAudioRecorder(recorder);
      setRecordingState("ready");
    } catch (error) {
      console.error("Microphone initialization error:", error);
      setErrorMessage(
        "Please allow microphone access and ensure it's not muted."
      );
    }
  };

  const stopMicrophoneUse = () => {
    if (microphoneStream) {
      microphoneStream.getTracks().forEach((track) => track.stop());
      setMicrophoneStream(null);
    }
  };

  useEffect(() => {
    initializeMicrophone();
    return () => {
      stopMicrophoneUse();
      if (audioRecorder && audioRecorder.mediaRecorder.state !== "inactive") {
        audioRecorder.stop();
      }
      clearInterval(countdownRef.current);
      clearTimeout(recordingTimeoutRef.current);
    };
  }, []);

  const handleStartRecording = async () => {
    try {
      setStartButtonDisabled(true);
      if (!audioRecorder) {
        setErrorMessage("Audio recorder not initialized.");
        return;
      }

      await audioRecorder.start();
      setIsRecording(true);
      setRecordingState("recording");

      // Start countdown
      let timeLeft = 15;
      countdownRef.current = setInterval(() => {
        timeLeft -= 1;
        setCountdown(timeLeft);
        if (timeLeft <= 0) {
          clearInterval(countdownRef.current);
          handleStopRecording();
        }
      }, 1000);

      // Set a timeout to stop recording after 15 seconds
      recordingTimeoutRef.current = setTimeout(() => {
        handleStopRecording();
      }, 15000);
    } catch (error) {
      console.error("Error starting recording:", error);
      setErrorMessage("An error occurred while starting the recording.");
    }
  };

  const handleStopRecording = async () => {
    clearTimeout(recordingTimeoutRef.current);
    if (audioRecorder) {
      const audioBlob = await audioRecorder.stop();
      setIsRecording(false);
      clearInterval(countdownRef.current);
      setCountdown(15);
      setRecordingState("processing");

      const blobUrl = URL.createObjectURL(audioBlob);
      console.log("Recorded audio Blob URL:", blobUrl);
      setAudioBlobUrl(blobUrl);

      await sendAudioToBackend(audioBlob);

      // Stop microphone use after processing
      stopMicrophoneUse();
    }
  };

  // Function to send audio to Firebase function
  const sendAudioToBackend = async (audioBlob) => {
    const formData = new FormData();
    formData.append("audio", audioBlob, "recording.mp4"); // Change to .mp4
    formData.append("userId", currentUser.uid);

    try {
      const response = await fetch(
        "https://uploadaudio-gelgl5ujjq-uc.a.run.app",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        onRecordingComplete(data);
      } else {
        setErrorMessage("Error during transcription. Please try again.");
      }
    } catch (error) {
      console.error("Error sending audio to backend:", error);
      setErrorMessage("An error occurred while processing the audio.");
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="relative bg-white p-6 rounded-lg w-full max-w-md">
        {/* Close Button */}
        <button
          onClick={onCancel}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          &#x2715;
        </button>

        {/* Header */}
        <h2 className="text-xl font-bold mb-4 text-center">
          Voice Task Recording
        </h2>

        {/* Error Message */}
        {errorMessage && showError(errorMessage)}

        {/* Content */}
        {recordingState === "ready" && (
          <div className="flex flex-col items-center">
            <div className="text-2xl font-bold mb-4">{countdown} seconds</div>
            <button
              onClick={handleStartRecording}
              disabled={startButtonDisabled}
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
            >
              Start Recording
            </button>
          </div>
        )}

        {(recordingState === "recording" || isRecording) && (
          <div className="flex flex-col items-center">
            <div className="text-2xl font-bold mb-4">{countdown} seconds</div>
            <div className="text-red-500 animate-pulse mb-2">Recording...</div>
            <button
              onClick={handleStopRecording}
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
            >
              Stop Recording
            </button>
          </div>
        )}

        {recordingState === "processing" && (
          <div className="flex flex-col items-center">
            <div className="mb-4">
              <div className="animate-spin h-16 w-16 border-4 border-blue-500 border-t-transparent rounded-full"></div>
            </div>
            <div className="text-xl font-bold">Processing...</div>
          </div>
        )}

      </div>
    </div>
  );
}

export default RecordingModal;
