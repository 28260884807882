export const startRecording = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioChunks = [];
      const mediaRecorder = new (window.MediaRecorder ||
        window.webkitMediaRecorder)(stream, {
        mimeType: "audio/mp4",
      });

      mediaRecorder.addEventListener("dataavailable", (event) => {
        audioChunks.push(event.data);
      });

      const start = () => mediaRecorder.start();

      const stop = () => {
        return new Promise((resolveStop) => {
          mediaRecorder.addEventListener("stop", () => {
            const audioBlob = new Blob(audioChunks, { type: "audio/mp4" });
            resolveStop(audioBlob);
          });

          mediaRecorder.stop();
        });
      };

      resolve({ start, stop });
    } catch (err) {
      reject(err);
    }
  });
};

export const initializeRecorder = (stream) => {
  return new Promise((resolve, reject) => {
    try {
      const audioChunks = [];
      let mimeType = "audio/webm";

      // Check if it's Safari
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      );

      if (isSafari) {
        mimeType = "audio/mp4";
      } else if (!MediaRecorder.isTypeSupported(mimeType)) {
        mimeType = "audio/ogg";
      }

      const mediaRecorder = new MediaRecorder(stream, { mimeType });

      mediaRecorder.addEventListener("dataavailable", (event) => {
        audioChunks.push(event.data);
      });

      let intervalId;

      const start = () => {
        return new Promise((resolveStart) => {
          audioChunks.length = 0; // Clear any previous recordings
          mediaRecorder.start(10); // Request data every 10ms

          // For Safari, we'll manually trigger dataavailable events
          if (isSafari) {
            intervalId = setInterval(() => {
              if (mediaRecorder.state === "recording") {
                mediaRecorder.requestData();
              }
            }, 1000); // Request data every second for Safari
          }

          resolveStart();
        });
      };

      const stop = () => {
        return new Promise((resolveStop) => {
          const handleStop = () => {
            if (intervalId) {
              clearInterval(intervalId);
            }
            const audioBlob = new Blob(audioChunks, { type: mimeType });
            resolveStop(audioBlob);
          };

          if (mediaRecorder.state === "inactive") {
            handleStop();
          } else {
            mediaRecorder.addEventListener("stop", handleStop);
            mediaRecorder.stop();
          }
        });
      };

      resolve({ start, stop, mediaRecorder });
    } catch (err) {
      reject(err);
    }
  });
};
