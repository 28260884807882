import React from 'react';
import { format, addHours } from 'date-fns';

function EmptyStateTaskCard() {
  // Pre-fill values
  const title = 'Create your first task';
  const notes = 'Add your notes to the task however you want';
  const today = new Date();
  const dueDate = format(today, 'dd MMMM yyyy');
  const dueTime = format(addHours(today, 2), 'hh:mm a');
  const flagName = 'Urgent';
  const flagColor = '#75bae0';

  // Styling adjustments for ghost-like appearance
  const ghostTextClass = 'text-gray-400';
  const ghostBgClass = 'bg-gray-100';

  return (
    <div className={`p-4 shadow-md rounded-lg flex flex-col justify-between h-full ${ghostBgClass}`}>
      <div className="flex justify-between items-start">
        <div>
          <h3 className={`text-md font-semibold mb-1 ${ghostTextClass}`}>{title}</h3>
          <p className={`mb-2 text-sm ${ghostTextClass}`}>{notes}</p>
        </div>
      </div>

      <div className="flex justify-between items-center mt-2">
        <p className={`text-xs ${ghostTextClass}`}>
          Due: {dueDate} At: {dueTime}
        </p>
        <div className="mt-1">
          <span
            className="inline-block px-2 py-1 rounded-sm text-xs"
            style={{
              backgroundColor: flagColor,
              color: '#fff',
              opacity: 0.5, // Make the flag lighter
            }}
          >
            {flagName}
          </span>
        </div>
      </div>
    </div>
  );
}

export default EmptyStateTaskCard;