// src/components/WebhookCard.js

import React, { useEffect, useState } from "react";
import { useAuth } from "../../common/contexts/AuthContext";
import { firestore } from "../../firebase"; // Ensure Firebase is correctly set up
import { doc, getDoc, updateDoc } from "firebase/firestore";
import dayjs from "dayjs";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

function WebhookCard() {
  const { currentUser } = useAuth();
  const [webhookUrl, setWebhookUrl] = useState("");
  const [testSuccess, setTestSuccess] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Fetch the saved webhook URL from Firebase
  useEffect(() => {
    const fetchWebhookUrl = async () => {
      if (currentUser) {
        try {
          const userDocRef = doc(firestore, "users", currentUser.uid);
          const userSnapshot = await getDoc(userDocRef);

          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            if (userData.webhookUrl) {
              setWebhookUrl(userData.webhookUrl);
            } else {
              setWebhookUrl(""); // Ensure state is cleared if webhookUrl doesn't exist
            }
          }
        } catch (error) {
          console.error("Error fetching webhook URL:", error);
          setErrorMessage("Error fetching webhook URL. Please try again.");
        }
      }
    };

    fetchWebhookUrl();
  }, [currentUser]);

  // Save the webhook URL to Firebase
  const handleSaveWebhook = async () => {
    if (currentUser) {
      try {
        const userDocRef = doc(firestore, "users", currentUser.uid);
        await updateDoc(userDocRef, { webhookUrl });
        setTestSuccess("Webhook URL saved successfully!");
        setErrorMessage("");
        setTimeout(() => setTestSuccess(""), 4000);
      } catch (error) {
        console.error("Error saving webhook URL:", error);
        setErrorMessage("Error saving webhook URL. Please try again.");
      }
    }
  };

  // Clear the webhook URL
  const handleClearWebhook = async () => {
    if (currentUser) {
      try {
        const userDocRef = doc(firestore, "users", currentUser.uid);
        await updateDoc(userDocRef, { webhookUrl: "" });
        setWebhookUrl(""); // Clear the local state as well
        setTestSuccess("Webhook URL cleared successfully!");
        setErrorMessage("");
        setTimeout(() => setTestSuccess(""), 4000);
      } catch (error) {
        console.error("Error clearing webhook URL:", error);
        setErrorMessage("Error clearing webhook URL. Please try again.");
      }
    }
  };

  // Test the webhook by sending a mock task JSON
  const handleTestWebhook = async () => {
    if (!webhookUrl.trim()) {
      setErrorMessage("Please enter a webhook URL before testing.");
      return;
    }

    const testTask = {
      title: "Test Task",
      notes: "This is a test task sent to your webhook.",
      dueDate: dayjs().format("DD MMMM YYYY"),
      dueTime: "03:00 PM",
      flag: "Test Flag",
    };

    try {
      const response = await fetch(webhookUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(testTask),
      });

      if (response.ok) {
        setTestSuccess("Test webhook triggered successfully!");
        setErrorMessage("");
      } else {
        setErrorMessage(
          "Failed to trigger webhook. Please check the webhook URL and ensure it is valid."
        );
      }
    } catch (error) {
      console.error("Error triggering webhook:", error);
      setErrorMessage(
        "Error triggering webhook. Please check the console for more details."
      );
    }

    setTimeout(() => {
      setTestSuccess("");
      setErrorMessage("");
    }, 4000);
  };

  return (
    <div className="max-w-full mx-4 my-6 p-6 bg-white shadow-md rounded">
      <h2 className="text-2xl font-bold mb-2">Webhook</h2>
      {/* Thin gray line below the title */}
      <div className="border-b border-gray-300 mb-4"></div>
      <p className="mb-4">
        If you want to post the task to another service, like Make or Zapier,
        add your webhook here, and we will be posting the title, notes, due
        date, and flag in a JSON to the webhook service.
      </p>

      {/* Webhook URL Input, Save, and Clear Buttons */}
      <div className="mb-4">
        <label className="block text-sm font-semibold mb-2">Webhook URL</label>
        <div className="flex items-center">
          <input
            type="text"
            value={webhookUrl}
            onChange={(e) => setWebhookUrl(e.target.value)}
            placeholder="https://your-webhook-url.com"
            className="flex-grow px-3 py-2 border rounded focus:outline-none focus:ring bg-gray-100 text-gray-700"
          />
          <button
            onClick={handleSaveWebhook}
            className="ml-2 bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
          >
            Save
          </button>
          <button
            onClick={handleClearWebhook}
            className="ml-2 bg-red-500 text-white px-4 py-2 rounded shadow hover:bg-red-600"
          >
            Clear
          </button>
        </div>
      </div>

      {/* Test Webhook Button */}
      <div className="flex justify-end mt-4">
        <button
          onClick={handleTestWebhook}
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
        >
          Test Webhook
        </button>
      </div>

      {/* Success Alert */}
      {testSuccess && (
        <div className="rounded-md bg-green-50 p-4 mt-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                aria-hidden="true"
                className="h-5 w-5 text-green-400"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-green-800">
                {testSuccess}
              </p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                  onClick={() => setTestSuccess("")}
                >
                  <span className="sr-only">Dismiss</span>
                  <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Error Alert */}
      {errorMessage && (
        <div className="rounded-md bg-red-50 p-4 mt-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon
                aria-hidden="true"
                className="h-5 w-5 text-red-400"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-red-800">
                {errorMessage}
              </p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  className="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                  onClick={() => setErrorMessage("")}
                >
                  <span className="sr-only">Dismiss</span>
                  <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default WebhookCard;