import React from 'react';

function ContactCard() {
  return (
    <div className="max-w-full mx-4 my-6 p-6 bg-white shadow-md rounded">
      <h2 className="text-2xl font-bold mb-2">Contact Me</h2>
      <div className="border-b border-gray-300 mb-4"></div>

      <p className="mb-4">
Whisprlist is part of the 20 apps in 20 weeks challenge, that my I decited to undertake with the assistance of ChatGPT and a friend. The goal of this is to make a single use tool that solves a real problem for people.       </p>
      <p className="mb-4">
        If you have any thoughts, comments, or suggestions, jump into the Discord and let's have a chat.
      </p>
      
      <div className="flex justify-end mt-6">
        <a 
          href="https://discord.gg/KjNpZevaTp" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
        >
          Join the Discord
        </a>
      </div>
    </div>
  );
}

export default ContactCard;